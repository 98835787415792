import React, { Component} from 'react';
import { Container, Col, Row, Card } from 'react-bootstrap';
import axios from 'axios';
import { Chat, Phone } from '@mui/icons-material';
import TimeAgo from 'react-timeago'
import {withRouter } from 'react-router-dom';
import config from '../../config'
const TP_API_URL = config.TP_API_URL;

let gcl = axios.CancelToken.source();
class Queue extends Component {
    constructor(props) {
        super(props);
        this.state = {
           cart:[],
           cart_details:[],
           cid:0,
           loading:1
        };
    }


    get_cart_list = (e) => {
        if (gcl) { gcl.cancel();}
        gcl = axios.CancelToken.source();
        const tp_agent_token = localStorage.getItem("tp_agent_token");
        axios.get(TP_API_URL+'/agent/cart/list', {
            headers:{token: tp_agent_token},
            cancelToken: gcl.token
        })
        .then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                this.setState({
                    cart: response.data,
                    loading:0
                });
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    
    process_cart = (e, id, i) => {
        const cartarray = this.state.cart;
        cartarray.splice(i, 1);
        this.setState({
            cart: cartarray,
            cid:0
        });

        const tp_agent_token = localStorage.getItem("tp_agent_token");
        const ax_headers = {
            headers: {
                token: tp_agent_token
            }
        }
        
        axios.post(TP_API_URL+'/agent/sms/queue', {
                cart_id:id
        }, ax_headers)
        .then((res) => {
            //const response = res.data;
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    view_cart = (e, id, i) => {
        const cart_details = this.state.cart[i];
        this.setState({
            cart_details: cart_details,
            cid: id
        });
    }


    componentDidMount(){
        this.get_cart_list();
    }


    render() {
        const { cart, cart_details } = this.state
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={6} className="lefthalf">
                            {this.state.loading===1 ? <div className="alert alert-secondary mt-20">Loading...</div> : ""}
                            {cart.map((item, i) => ( 
                                <Card className="mt-10 queue">
                                    <Card.Body>
                                        <div className="d-flex align-items-center">
                                            <div className="timezone">{item.timezone}</div>
                                            <div className="flex-grow-1 cursor-pointer" onClick={ e => this.view_cart(e, item.id, i)}>
                                                <h6>{item.cart_name} <small><Phone />{item.cart_phone}</small></h6>
                                                <p>{item.shop_name} - <TimeAgo date={item.created_on} /></p>
                                            </div>
                                            <div className="price">${item.order_total}</div>
                                            <div className=""> 
                                                <button className="btn btn-primary" onClick={ e => this.process_cart(e, item.id, i)}><Chat/> Send</button>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                                ))
                            }
                        </Col>

                        <Col md={6} className="righthalf">
                            {
                                this.state.cid>0 ?
                                <Card className="mt-20">
                                    <Card.Body>
                                        <h6><small>Name</small> {cart_details.cart_name}</h6>
                                        <h6><small>Phone</small> {cart_details.cart_phone}</h6>
                                        <h6><small>Email</small> {cart_details.cart_email}</h6>
                                        <h6><small>Shop name</small> {cart_details.shop_name}</h6>
                                        <h6><small>Shop domain</small> {cart_details.domain}</h6>
                                        <h6><small>Checkout id</small> {cart_details.checkout_id}</h6>
                                        <h6><small>Cart link</small> {cart_details.cart_url}</h6>
                                        <h6><small>Total price</small> ${cart_details.order_total}</h6>
                                        <h6><small>Zip</small> {cart_details.cart_zip}</h6>
                                        <h6><small>Country</small> {cart_details.cart_country}</h6>
                                        <h6><small>Created date</small> {cart_details.created_on}</h6>
                                        <h6><small>Updated date</small> {cart_details.updated_on}</h6>
                                        <h6><small>Timezone</small> {cart_details.timezone}</h6>
                                    </Card.Body>
                                </Card>
                                : <Card className="mt-20">
                                    <Card.Body> 
                                        <Card.Text className="text-center">
                                            Click on cart to view it's details.
                                        </Card.Text>
                                    </Card.Body>
                                  </Card>
                            }
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}


export default withRouter(Queue);
