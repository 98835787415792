import React from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/pd-mr.css';
import './assets/App.css';

/* Agent Panel */

import AgentPanel from "./wrapper/AgentPanel.js";
import Chat  from './pages/agent/Chat.js';
import Queue from './pages/agent/Queue.js'
import Logout from './pages/agent/Logout.js'
import Login from './pages/agent/Login.js';

import PageNotFound from './pages/PageNotFound.js'


function App() {
  
  return (
      <BrowserRouter>
          <Switch>
              <Route exact path="/"><AgentPanel><Chat /></AgentPanel></Route>
              <Route path="/queue"><AgentPanel><Queue /></AgentPanel></Route>
              <Route path="/logout"><Logout /></Route>
              <Route path="/login"><Login /></Route>
              <Route path="*" component={PageNotFound} />
          </Switch>
      </BrowserRouter>
  );
}

export default App;
